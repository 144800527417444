<template>
	<div>
		<div v-title data-title="多对多SaaS管理平台"></div>
		<nav class="page_head_nav">
			<p>菜单管理</p>
			<i>/</i>
			<p>菜单列表</p>
		</nav>
	
		<!-- <div class="head_search_wrap">
	
			<div class="search_one_line">
				<div class="left">
					<el-form label-width="120px" @submit.native.prevent>
						<el-form-item label="菜单名称:">
							<el-input class="input_medi" v-model="keyword" @keyup.enter.native="searchFn" clearable placeholder="请输入菜单名称"></el-input>
						</el-form-item>
					</el-form>
				</div>
				<div class="right">
					<p class="reset" @click="resetFn">重置</p>
					<p class="search" @click="searchFn">查询</p>
				</div>
			</div>
	
		</div> -->
	
		<div class="page_table">
			<div class="page_table_title">
				<div class="left">
					<p>菜单列表</p>
				</div>
				<div class="right">
					<span class="span1" @click="addFn">新增</span>
				</div>
			</div>
			<el-table :data="listData" row-key="id" :tree-props="{children: 'second'}" default-expand-all class="tb-edit" style="width: 100%" highlight-current-row>
				<el-table-column prop="menu_name" label="菜单名称" align="left"></el-table-column>
				<el-table-column prop="menu_spell" label="别称" align="center"></el-table-column>
				<el-table-column prop="menu_action" label="后端路由" align="center"></el-table-column>
				<el-table-column prop="frontend_route" label="前端路由" align="center"></el-table-column>
				<el-table-column prop="sort" label="排序" align="center"></el-table-column>
				<el-table-column prop="create_time" label="创建时间" align="center"></el-table-column>
				<el-table-column prop="" label="是否展示" align="center">
					<template slot-scope="scope">
						<div class="status_color">
							<p v-if="scope.row.is_show == 1" class="p1">展示</p>
							<p v-else class="p2">不展示</p>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="" label="状态" align="center">
					<template slot-scope="scope">
						<div class="status_color">
							<p v-if="scope.row.is_enable == 1" class="p1">激活</p>
							<p v-else class="p2">冻结</p>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="操作" align="center" width="140px">
					<template slot-scope="scope">
						<div class="operation">
							<span @click="modifyFn(scope.row)">修改</span>
							<span v-if="scope.row.menu_rank == '1'" @click="twoMenuAddFn(scope.row)">新增二级</span>
						</div>
					</template>
				</el-table-column>
			</el-table>
			
			<div class="block">
				<el-pagination @current-change="currentChange" :current-page="currentPage1" :total="total">
				</el-pagination>
			</div>
			
		</div>
		
	</div>
</template>

<script>
	export default {
		name: 'menuRunList',
		data() {
			return {
				listData: [], // 列表数据
				total: 0, //总页数
				currentPage1: 1, //当前页码
				keyword: '', // 关键字搜索
			}
		},
		components: {
			
		},
		created() {
			// 获取产品分类列表
			this.getGoodsList();
		},
		methods: {
			// 重置
			resetFn() {
				this.keyword = '';
				this.currentPage1 = 1;
				this.getGoodsList();
			},

			// 搜索
			searchFn() {
				this.currentPage1 = 1;
				this.getGoodsList();
			},
			
			// 获取菜单列表
			getGoodsList(){
				this.$sendHttp({
					method: 'POST',
					url: `${process.env.VUE_APP_SAAS}menu/list`,
					data: {}
				}).then( res => {
					if(res.code == '0'){
						this.total = res.count;
						this.listData = res.data;
					}
				})
			},

			// 分页器函数
			currentChange(val) {
				this.currentPage1 = val;
				this.getGoodsList();
			},

			// 修改
			modifyFn(row) {
				this.$router.push({
					path: '/menuEdit',
					query: {
						id: row.id,
						parent_id: row.parent_id,
					}
				})
			},
			
			// 新增
			addFn(){
				this.$router.push({
					path: '/menuEdit',
					query:{
						parent_id: 0,
					}
				});
			},
			
			// 二级菜单新增
			twoMenuAddFn(row){
				this.$router.push({
					path: '/menuEdit',
					query:{
						parent_id: row.id,
					}
				});
			},

		}
	}
</script>
<style scoped lang="less">
	/* 加长input */
	.input_long_plus {
		width: 500px;
	}

	/* 长input */
	.input_long {
		width: 400px;
	}

	/* 中等input */
	.input_medi {
		width: 200px;
	}

	/* mini input */
	.input_mini {
		width: 100px;
	}
</style>
